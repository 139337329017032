@import 'styles/breakpoints';
@import 'styles/mixins';

.blockLocation {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &.bgWhite {
        background-color: var(--colors-white);
    }
}

.blockLocationInner {
    position: relative;
    display: flex;
    width: 100%;
    max-width: var(--block-max-width);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--colors-blue);
}

.textEyebrow {
    padding: 0 0 var(--block-padding-end);
}

.locationCard {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding: var(--block-padding);
    color: var(--colors-blue);
}

.logoWrapper {
    position: relative;
    width: var(--logo-width, 28.7rem);

    img {
        width: 100%;
    }
}

.address {
    max-width: var(--address-max-width, 28.7rem);
    margin-top: 2.6rem;
    text-align: center;
    text-decoration: underline;
}

.reservationButton {
    margin-top: 4.6rem;
}

.hoursWrapper {
    width: 100%;
}

.hoursLabel {
    text-transform: uppercase;
}

.hoursList {
    padding: 0;
    margin: 1rem 0 2rem;
    list-style: none;

    li {
        &::after {
            display: block;
            width: 100%;
            height: 0.1rem;
            background-color: var(--colors-blue);
            content: '';
            opacity: 0.25;
        }
    }
}

.timeSpan {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: 0.1rem solid rgba(255, 252, 230, 25%);
}

.contactWrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-top: 4rem;
}

.contactLabel {
    margin-bottom: 2rem;
    text-transform: uppercase;
}

.contactName,
.contactEmail,
.contactNumber {
    margin-bottom: 0.5rem;
}

.contactEmail,
.contactNumber {
    text-decoration: underline;
}

.menuButton {
    margin-top: var(--menu-button-margin-top, 1.2rem);
}

.customLink {
    display: block;
    margin-bottom: 2rem;
    text-decoration: underline;
}

.column {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.columnInner {
    width: 100%;
    max-width: 49.2rem;
    padding: 4.8rem 2.4rem;
    margin-top: 4.8rem;
    background-color: var(--colors-blue);
    color: var(--colors-white);

    @include rounded-corners;
}

@include medium {
    .locationCard {
        flex-direction: row;
    }

    .column {
        width: 50%;
    }

    .columnInner {
        max-width: 60rem;
        margin: 0;
    }
}

@include large {
    .logoWrapper {
        --logo-width: 29.8rem;
    }

    .address {
        margin-top: 3rem;

        --address-max-width: none;

        .market & {
            max-width: 39rem;
        }
    }

    .column {
        padding-right: 2.4rem;

        &:last-child {
            padding-right: 0;
            padding-left: 2.4rem;
        }
    }

    .reservationButton {
        margin-top: 5.6rem;
    }
}

@include xxLarge {
    .logoWrapper {
        --logo-width: 37.8rem;
    }

    .locationCard {
        padding: var(--block-padding-end);
    }

    .menuButton {
        --menu-button-margin-top: 0;

        margin-left: 1.2rem;
    }
}
